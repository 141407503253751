import React from "react"
import { Link } from "gatsby"
import dataimage from "../../assets/images/banners/service-images/data.png"

import batch from "../../assets/images/banners/icon-pack/batch-processing.png"
import issues from "../../assets/images/banners/icon-pack/caution-sign.png"
import lake from "../../assets/images/banners/icon-pack/data-lake.png"
import etl from "../../assets/images/banners/icon-pack/data-transformation.png"
import warehouse from "../../assets/images/banners/icon-pack/data-warehouse.png"
import integration from "../../assets/images/banners/icon-pack/technology.png"

const DataScienceAnalytics = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img data"
          data-bg="assets/images/banners/service-banners/data-science.png"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Data Science & Analytics</h1>
                  <h5 className="font-weight--normal text-white">
                    Employ big data experts from Aamanto, a company that
                    provides big data services, to assist you in selecting the
                    best infrastructure solutions.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== About Company Area Start ==========-->
             <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our IT Service</h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Data Science <span className="text-color-primary">& Analytics</span></h2>
                  As digital transformation is a journey, not a
                  destination, you will need an experienced team to
                  assist you in leveraging new technologies and reaping
                  the benefits of being a digital business. Aamanto
                  Digital Transformation practice is built on
                  considerable experience transforming legacy systems
                  into cutting-edge design environments. We know how to
                  break down internal barriers, move and improve
                  applications in the cloud, accelerate custom software
                  development operations, and execute a data-driven
                  approach to digital service delivery. Engage with
                  Aamanto Technologies to speed up your cloud migration,
                  develop new consumer experiences, rethink business
                  strategies, and boost efficiency and productivity. We
                  operate quickly to accomplish your objective in a
                  secure, dependable, and scalable manner.
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={dataimage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

             <!--===========  feature-icon-wrapper  Start =============--> */}
        <div className="feature-icon-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Our Spectrum <span className="text-color-primary">Of Big Data Analysis</span></h2>
                  <p>To Make Better Business Decisions, Leverage Big Data Analytics Solutions.</p>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12">
                <div className="feature-list__three">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-shield-check"></i>
                              </div>
                              <h5 className="heading">Data Warehousing</h5>
                            </div>
                            <div className="content">
                              <div
                                className="text"
                                style={{ textAlign: "justify" }}
                              >
                                You may get real-time data warehousing with
                                Aamanto, which includes change data capture
                                (CDC) and optimal integration to numerous data
                                warehouse platforms.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-lock-alt"></i>
                              </div>
                              <h5 className="heading">Data Related Issues</h5>
                            </div>
                            <div className="content">
                              <div
                                className="text"
                                style={{ textAlign: "justify" }}
                              >
                                We efficiently address any data-related issues
                                and provide a comprehensive solution for
                                translating and processing raw data into refined
                                data.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-magnet"></i>
                              </div>
                              <h5 className="heading">Data Lake</h5>
                            </div>
                            <div className="content">
                              <div
                                className="text"
                                style={{ textAlign: "justify" }}
                              >
                                Using Hadoop’s data environments, one can build,
                                deploy, and analyze data quicker. Our Data Lake
                                services automate the processing of big data in
                                the cloud in a really cost-effective and
                                efficient manner.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-medal"></i>
                              </div>
                              <h5 className="heading">ETL for Analytics</h5>
                            </div>
                            <div className="content">
                              <div
                                className="text"
                                style={{ textAlign: "justify" }}
                              >
                                With extraction of data from databases, a
                                transformation that includes cleaning, sorting,
                                and validating rules, and loading of data into a
                                data warehouse, data is retrieved from
                                operational systems and pre-processed for
                                analysis.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-medal"></i>
                              </div>
                              <h5 className="heading">
                                Data Integration Services
                              </h5>
                            </div>
                            <div className="content">
                              <div
                                className="text"
                                style={{ textAlign: "justify" }}
                              >
                                At Aamanto, we provide highly adaptable and
                                cost-effective Data Integration services across
                                a wide range of domains, as well as solutions to
                                complicated problems. Access to sensitive
                                information within your organization helps in
                                making better business decisions.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-shield-check"></i>
                              </div>
                              <h5 className="heading">Batch Processing</h5>
                            </div>
                            <div className="content">
                              <div
                                className="text"
                                style={{ textAlign: "justify" }}
                              >
                                Our method simplifies and speeds up the
                                collection and processing of large amounts of
                                data. We employ one-of-a-kind processing, input,
                                and output programs that are suited for a wide
                                range of activities.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div class="v1-box-2-parent">
              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={warehouse} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Data Warehousing</h5>
                            <div className="v1-text-2">
                              You may get real-time data warehousing with Aamanto, which includes change data capture (CDC) and optimal integration to numerous data warehouse platforms.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={issues} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Data Related Issues</h5>
                            <div className="v1-text-2">
                              We efficiently address any data-related issues and provide a comprehensive solution for translating and processing raw data into refined data.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={lake} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Data Lake</h5>
                            <div className="v1-text-2">
                              Using Hadoop’s data environments, one can build, deploy, and analyze data quicker. Our Data Lake services automate the processing of big data in the cloud in a really cost-effective and efficient manner.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={etl} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">ETL for Analytics</h5>
                            <div className="v1-text-2">
                              With extraction of data from databases, a transformation that includes cleaning, sorting, and validating rules, and loading of data into a data warehouse, data is retrieved from operational systems and pre-processed for analysis.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={integration} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Data Integration Services</h5>
                            <div className="v1-text-2">
                              At Aamanto, we provide highly adaptable and cost-effective Data Integration services across a wide range of domains, as well as solutions to complicated problems. Access to sensitive information within your organization helps in making better business decisions.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={batch} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Batch Processing</h5>
                            <div className="v1-text-2">
                              Our method simplifies and speeds up the collection and processing of large amounts of data. We employ one-of-a-kind processing, input, and output programs that are suited for a wide range of activities.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div >

            </div >

          </div >
        </div >
        {/* <!--===========  feature-icon-wrapper  End =============-->

            <!--=========== About Company Area End ==========--> */}
      </div >
    </div >
  )
}
export default DataScienceAnalytics

import * as React from "react"
import Layout from "../components/layout"
import DataScienceAnalytics from "../components/Services/DataScienceAnalytics"






const data = () => {
  return (
  <Layout>
   <DataScienceAnalytics/>
  </Layout>
)
};
export default data;
